.Text {
  display: grid;
  grid-template-columns: 1fr;

  grid-template-rows: auto;
  grid-gap: 24px;
  justify-content: center;

  align-items: baseline;
  
  padding-top: 94px;
  padding-bottom: 94px;
  padding-right: 24px;
  padding-left: 24px;
}

@media print {
  .Text {
    grid-gap: 8px;

    padding-top: 48px;
    padding-bottom: 32px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (min-width: 750px) {
.Text {
    grid-template-columns: min-content min-content minmax(400px,32em);
  }
}

@media print {
.Text {
    grid-template-columns: min-content min-content minmax(400px,32em);
  }
}

.section {
  display: flex;
  flex-flow: row wrap;
}


h1, h2, h3 {
  margin: 0;
  margin-top: 2em;
}

h2 {
  margin-top: 0.1em;
}

h3 {
  color: var(--color-grey);
  margin-top: 0px;
}

.headerContainer {
  height: 100%;
}

.header {
  text-align: left;
}

@media screen and (min-width: 750px) {
  .header {
    text-align: right;
    position: sticky;
    top: 8px;
  }
}


.content {
  max-width: 50em;
  /*margin-bottom: 40px;*/
}

p, ol, ul {
  margin: 0;
  margin-bottom: 1em;
  line-height: 1.5em;
  text-align: left;
}

