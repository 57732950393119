
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

:root {
  --color-body: #333355;
  --color-grey: #b6b6d3;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: var(--color-body);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-weight: 300;
  text-align: left;
}

h1 {
  font-size: 1.6rem;
  font-family: 'Libre Baskerville', serif;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1rem;
}

p, ol, ul {
  font-weight: 300;
  text-align: left;
}

ol, ul {
  padding-left: 24px;
}

@media print {
  html {
    font-size: 14px;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    page-break-inside: avoid;
  }
}

a {
  color: #ff25ab;
  background: transparent;
  transition: color 2s ease-in, background 0.2s ease-in-out, text-decoration 2s ease-in;
  border-radius: 3px;
  padding: 2px;
  text-decoration: underline dotted #ff25ab 1.2px
}

a:hover {
  color: #c31ae2;
  background: #eef7ff;
  text-decoration: underline dotted #c31ae2 1.2px
}

.outerContainer {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

mark {
  background: #ebebf4;
  color: var(--color-body);
  border-radius: 3px;
  font-size: 1rem;
  padding: 0px 4px;
}

.navigation {
  margin: 14px;
  padding: 0px;
  width: max-content;
  padding-bottom: 65px;
}

.navigation ul {
  display: flex;
  flex-flow: column wrap;
  max-width: 320px;
  margin: 0px;
  padding: 0px;
}

