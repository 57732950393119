
.App {
  overflow: scroll;
  height: 100vh;
}

@media print {
    .App {
        overflow: auto;
        height: auto;
    }
}


